<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="card card-custom">
        <div class="card-body p-0">
          <!--begin: Wizard-->
          <div
            class="wizard wizard-1"
            id="kt_wizard_v1"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Body-->
            <div
              class="row justify-content-center my-10 px-8 my-lg-10 px-lg-8"
            >
              <div class="col-xl-12">
                <!--begin: Wizard Form-->
                <Form
                  :form="form"
                  :title="'Edit Pembina Posyandu'"
                  :route="`api/staffs/${form.id}`"
                  purpose="edit"
                />
                <!--end: Wizard Form-->
              </div>
            </div>
            <!--end: Wizard Body-->
          </div>
        </div>
        <!--end: Wizard-->
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from "@/view/components/staff/Form.vue";
import module from "@/core/modules/CrudModule.js";

export default {
  components: {
    Form,
  },
  data() {
    return {
      form: {},
    };
  },
  methods:{
    async get() {
      this.form = await module.get('api/staffs/' + this.$route.params.id)
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        this.$router.push('/staffs')
      } else {
        this.form['_method'] = 'put'
        this.dataLoaded = true
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pembina Posyandu", route: "/staffs" },
      { title: "Edit" },
    ]);

    this.get()
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 250px !important;
  height: 250px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>